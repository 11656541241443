import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <h2 className="tp-pt-size mb-40">
                  Наши ценности <br/>и принципы
                  </h2>
                </div>
                


                <p className="tp-section-text">
                Мы - команда профессионалов в области IT-консалтинга, которая стремится к воплощению вашей компании в мире цифровых возможностей.
                </p>
                <p className="tp-section-text">
                Наша миссия - помочь предприятиям достичь успеха путем оптимизации бизнес-процессов, применения передовых технологий и привнесения инноваций.
                </p>
                <p className="tp-section-text">
                Наш коллектив состоит из опытных специалистов, готовых поделиться своими знаниями и опытом, чтобы помочь вашей компании процветать.
                </p>
                
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-page.jpg" alt="" />
                </div>
                <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
