import React from "react";
import { Link } from "react-router-dom";

const TopCtaAreaTwo = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area tp-cta-area-page-two pl-110 pr-200">
        <div className="container-fluid">
          <div className="tp-cta-wrapper pt-45 pb-105">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="tp-section-wrapper">
                  <h2 className="tp-section-title tp-section-title-large">
                    <span className="cc">IT-консалтинг</span>, основанный на&nbsp;данных и экспертизе
                  </h2>
                  <h4 className="main-subtitle">Наша команда экспертов поможет вам реализовать проекты по&nbsp;бизнес аналитике, диджитал трансформации и&nbsp;консалтинговой поддержке внедрений методологий</h4>
                </div>
              </div>
              <div className="col-lg-4 d-flex justify-content-lg-end" style={{marginTop: "30px"}}>
                <Link to="/contact">
                  <div className="tp-theme-btn d-flex align-items-center white-bg">
                    <div className="tp-btn-inner">
                      <p className="mb-0">Есть вопросы?</p>
                      <b>Обсудить проект</b>
                      <span className="d-inline-block">
                        <svg
                          width="46"
                          height="8"
                          viewBox="0 0 46 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="46"
                          height="8"
                          viewBox="0 0 46 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default TopCtaAreaTwo;
