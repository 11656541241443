import React from "react";
import Brands from "../common/Brands/Brands";
import Footer from "../common/Footer/Footer";
import FullScreenBtn from "../common/FullScreenBtn";
import Menu from "../common/Menu/Menu";
import Testimonial from "../common/Testimonial/Testimonial";
import BlogHomeTwo from "./BlogHomeTwo";
import GalleryHomeTwo from "./GalleryHomeTwo";
import Services from "./Services";
import SliderHomeTwo from "./SliderHomeTwo";
import TopCategoryHomePageTwo from "./TopCategoryHomePageTwo";
import TopCtaAreaTwo from "./TopCtaAreaTwo";
import TopAbout from "./../Home/TopAbout";
import TopCtaArea from "./../Home/TopCtaArea";
import CategoryThree from "./../HomeThree/CategoryThree";

const HomeTwo = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <TopCtaAreaTwo />
      <SliderHomeTwo />
      <TopCtaArea />
      <TopAbout />
      <Services />
      <CategoryThree />
      <FullScreenBtn />
      <Footer />
    </>
  );
};

export default HomeTwo;
