import React from "react";
import { Link } from "react-router-dom";

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-40 pb-200">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                  <h2 className="tp-section-title pt-25 mb-30">
                   {/* Algolio — <br/>IT-консалтинговая компания */}
                   Специализируемся на&nbsp;предоставлении <span className="cc">высококачественных услуг IT-консалтинга</span>
                  </h2>
                  <p className="tp-section-text">
                    Мы&nbsp;специализируемся на&nbsp;предоставлении высококачественных услуг IT-консалтинга, помогая компаниям достичь своих бизнес-целей с&nbsp;помощью технологических решений.
                  </p>
                  <p className="tp-section-text">
                    Наша команда экспертов поможет вам реализовать проекты по&nbsp;бизнес аналитике, диджитал трансформации и&nbsp;консалтинговой поддержке внедрений методологий. Раздвиньте границы своего бизнеса вместе с&nbsp;нами!
                  </p>
                </div>
                <div className="mt-45 mb-30"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  <img src="assets/img/about/about.jpg" alt="" />
                </div>
                <div className="tp-about-info text-center grey-bg-4">
                  <h3>2+</h3>
                  <h4>
                    Года на рынке<br />
                    консалтинга
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
