import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-from-section pt-140">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="tp-section-title" style={{"margin-bottom": "30px"}}>
                E-mail: <span className="cc">mail@algolio.ru</span>
              </h2>
              <h2 className="tp-section-title">
                Адрес: <span className="cc">390011, Рязанская область, г. Рязань, ул. Рязанская, д.22Д, пом./офис Н20/323</span>
              </h2>
              <br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tp-ct-form white-bg pl-110 pr-110 pt-80 pb-130">
                <form id="contact-form" action="assets/mail.php" method="POST">
                  <input
                    type="text"
                    name="name"
                    placeholder="Ваше имя*"
                    required
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Телефон*"
                    required
                  />
                  <textarea
                    name="message"
                    placeholder="Опишите кратко задачу"
                  ></textarea>
                  <div className="text-center">
                    <button type="submit" className="tp-btn-border">
                      Отправить заявку{" "}
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
                <p className="ajax-response mt-20 text-center"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-ct-map">
          <div style={{"position": "relative", "overflow": "hidden", "height": "100%"}}>
            <iframe src="https://yandex.ru/map-widget/v1/?ll=39.760862%2C54.582939&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzMyNzkyOBJc0KDQvtGB0YHQuNGPLCDQoNGP0LfQsNC90YwsINGA0LDQudC-0L0g0J3QuNC60YPQu9C40YfQuCwg0KDRj9C30LDQvdGB0LrQsNGPINGD0LvQuNGG0LAsIDIy0JQiCg1PCB9CFaZVWkI%2C&z=16.38" width="100%" height="100%" frameBorder="1" allowFullscreen="true" style={{"position": "relative"}} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
