import React from "react";
import { Link } from "react-router-dom";

const TopCtaArea = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area">
        <div className="container">
          <div className="tp-cta-wrapper pt-105 pb-120">
            <div className="row align-items-center justify-content-evenly">
              <div className="col-lg-9">
                <div className="tp-section-wrapper">
                  <h2 className="tp-section-title">
                  Мы&nbsp;&mdash; <span className="cc">команда профессионалов <br/>в&nbsp;области IT-консалтинга</span>, которая стремится к&nbsp;воплощению вашей компании в&nbsp;мире цифровых возможностей.
                  </h2>

                  <h2 className="tp-section-title tp-section-title-two">
                  Наша миссия&nbsp;&mdash; помочь предприятиям <span className="cc">достичь успеха путем оптимизации бизнес-процессов, применения передовых технологий и&nbsp;привнесения инноваций</span>.
                  </h2>

                  <h2 className="tp-section-title tp-section-title-two">
                  Наш коллектив состоит из&nbsp;опытных специалистов, готовых поделиться своими знаниями и&nbsp;опытом, чтобы <span className="cc">помочь вашей компании процветать</span>.
                  </h2>
                </div>
              </div>
              <div className="col-lg-3 d-flex justify-content-lg-end">
                
              </div>
            </div>
          </div>
         
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default TopCtaArea;
