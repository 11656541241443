const menu_data = [
  {
    id: 1,
    hasDropdown: false,
    title: 'Главная',
    link: '/',
  },
  {
    id: 2,
    title: 'О компании',
    link: '/about',
  },
  {
    id: 3,
    hasDropdown: false,
    title: 'Услуги',
    link: '/service',
  },
  {
    id: 4,
    hasDropdown: false,
    title: 'Контакты',
    link: '/contact',
  },
]

export default menu_data;
