import React from "react";
import { ServiceItem } from "../../HomeTwo/Services";

const OurService = () => {
  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <h2 className="tp-section-title">Полный комплекс услуг <br />для развития вашего бизнеса</h2>
              </div>
            </div>
          </div>
          <div className="row">
          <ServiceItem
              icon="pe-7s-arc"
              title="Бизнес <br/>аналитика"
              desc=""
              to="/service/business-analytics"
            />

            <ServiceItem
              icon="pe-7s-cloud-download"
              title="Диджитал-трансформация <br/>компаний"
              desc=""
              to="/service/digital-transformation"
            />

            <ServiceItem
              icon="pe-7s-disk"
              title="Консалтинговая поддержка <br/>внедрений методологий"
              desc=""
              to="/service/consulting-metodology"
            />
            
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
