import React from "react";

const CategoryThree = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area pt-140">
        <div className="container">
          <div className="tp-cta-wrapper">
            <div className="row">
              <div className="col-12">
                <div className="tp-section-wrapper text-center">
                  <h2 className="tp-section-title">
                  Не&nbsp;стесняйтесь связаться с&nbsp;нами, чтобы обсудить ваши потребности и&nbsp;задачи. Мы&nbsp;доступны для консультаций, ответов на&nbsp;вопросы и&nbsp;оказания поддержки.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default CategoryThree;
