import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import FullScreenBtn from "../../common/FullScreenBtn";
import Testimonial from "../../common/Testimonial/Testimonial";
import CategoryThree from "../../HomeThree/CategoryThree";
import Gallery from "../../Home/Gallery";
import Services from "../../HomeTwo/Services";
import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";
import AboutFeature from "./AboutFeature";


const About = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <AboutBanner />
      <AboutCompany />
      <Gallery />
      <CategoryThree />
      <FullScreenBtn />
      <Footer />
    </>
  );
};

export default About;
