import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          Услуги
                        </h3>
                        <ul>
                          <li>
                            <a href="/service/business-analytics">Бизнес-аналитика</a>
                          </li>
                          <li>
                            <a href="/service/digital-transformation">Диджитал трансформация компаний</a>
                          </li>
                          <li>
                            <a href="/service/consulting-metodology">Консалтинговая поддержка внедрений методологий</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Algolio</h3>
                        <ul>
                          <li>
                            <a href="/about">Компания</a>
                          </li>
                          <li>
                            <a href="/service">Услуги</a>
                          </li>
                          <li>
                            <a href="/contact">Контакты</a>
                          </li>
                          <li>
                            <a href="#">Политика конфиденциальности</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Контакты</h3>
                        <ul>
                          <li>
                            <a href="/contact">
                            390011, Рязанская область, г. Рязань, ул. Рязанская, д.22Д, пом./офис Н20/323
                            </a>
                          </li>
                          <li>
                            <br/>
                          </li>
                          <li>
                            <a href="mailto:mail@algolio.ru">
                            mail@algolio.ru
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} Algolio — Все права защищены. Не является публичной офертой.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="tp-footer-social-icon mb-30 text-md-end">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-telegram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
