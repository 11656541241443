import React from "react";

const ServiceDetailsAbout = ({children}) => {
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsAbout;
